function Education() {
  return (
    <div className="education-section">
      <h2 className="sub-title">Education</h2>
      <ul className="degree-list">
        <li className="degree">
          Master of Science in <mark>Computer Science</mark> , Human-Computer
          Interaction, The University of British Columbia, <mark> 2023</mark>
        </li>
        <li className="degree">
          Master of Science in <mark>Information Systems</mark>, ICT Innovator,
          Kobe Institute of Computing, <mark> 2020</mark>
        </li>
        <li className="degree">
          Bachelor of Science in <mark>Computer Engineering</mark>, computer
          networking & embedded systems, Princess Sumaya University for
          Technology, <mark> 2016</mark>
        </li>
      </ul>
    </div>
  );
}

export default Education;
