import pic from "./assets/pic.jpg";
import "./style/App.css";
import Education from "./components/Education";
import Theses from "./components/Theses";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="header-container">
      <img src={pic} alt="Profile" className="profile-image" />

      <div className="bio">
        <h1 className="title">Yaman Sanobar</h1>I have a multidisciplinary
        background in the theoretical research and the technical development of
        designing and developing <strong>applications</strong>. I have been
        involved in numerous projects with multidisciplinary teams, which shaped
        my interest in the app development field.
        <br />
        <br />I have two years of experience as a web developer:
        <mark> 2017-2018</mark>, and I received the Cisco CCNA Certificate in
        <mark> 2017</mark>. I participated in the Hult Prize competition in{" "}
        <mark> 2019</mark>, and I received the Japanese-Language Proficiency
        Test N4 in<mark> 2020</mark>.
        <br />
        <br />
        {/* add link to foudnation */}
        Currently I’m a full-stack development Instructor at{" "}
        <a
          href="https://www.foundation4ward.org/"
          target="_blank"
          rel="noreferrer"
        >
          Foundation Forward
        </a>
        <div className="spacing"></div>
        <Education />
        <div className="spacing"></div>
        <Theses />
      </div>
      <Footer />
    </div>
  );
}

export default App;
